<template>
  <section v-if="items.length > 0" class="elementor-element elementor-element-35fa3de7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible elementor-section elementor-top-section" data-id="35fa3de7" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:500}">
    <div class="elementor-container elementor-column-gap-default">
      <div class="elementor-row">
				<div class="elementor-element elementor-element-11cbce41 elementor-column elementor-col-100 elementor-top-column" data-id="11cbce41" data-element_type="column">
          <div class="elementor-column-wrap  elementor-element-populated">
            <div class="elementor-widget-wrap">
              <div class="elementor-element elementor-element-57422a1f elementor-pagination-position-outside elementor-widget elementor-widget-image-carousel" data-id="57422a1f" data-element_type="widget" data-settings="{&quot;slides_to_show&quot;:&quot;4&quot;,&quot;slides_to_scroll&quot;:&quot;1&quot;,&quot;navigation&quot;:&quot;dots&quot;,&quot;slides_to_show_tablet&quot;:&quot;3&quot;,&quot;slides_to_scroll_tablet&quot;:&quot;1&quot;,&quot;slides_to_show_mobile&quot;:&quot;2&quot;,&quot;slides_to_scroll_mobile&quot;:&quot;1&quot;,&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;speed&quot;:500,&quot;direction&quot;:&quot;ltr&quot;,&quot;image_spacing_custom&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:20,&quot;sizes&quot;:[]}}" data-widget_type="image-carousel.default">
                <div class="elementor-widget-container">
                  <div class="elementor-image-carousel-wrapper swiper-container" dir="ltr">
                    <div class="elementor-image-carousel swiper-wrapper swiper-image-stretch">
                      <div  v-for="(item, $itemCount) in items" v-if="item.Image !== null" class="swiper-slide">
                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="57422a1f" data-elementor-lightbox-title="kat-191028_501707" href="#">
                          <figure class="swiper-slide-inner">
                            <div class="slider-item-title-wrapper">
                              <div class="slider-item-title">
                                {{ item.Content.Name }}
                              </div>
                              <a href="#" class="d21-trigger-ibe slider-item-link" :data-d21="'{\'package_id\':' + item._LegacyId + '}'">
                                mehr zum Angebot
                              </a>
                            </div>
                            <a href="#" class="d21-trigger-ibe" style="display:block;" :data-d21="'{\'package_id\':' + item._LegacyId + '}'"><img class="swiper-slide-image" :src="renderImage(item)" alt="item.Content.Name"/></a>
                          </figure>
                        </a> 
                 
                      </div>
                    </div>
                    <div class="swiper-pagination"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
		    </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueCropper }  from 'vue-cropper' 
import { clientId, clientSecret }  from '../env' 

export default {
  name: 'Packages',
  components: {
    VueCropper
  },
  props: {
    msg: String
  },
  created() {
      if(packages == '') {
        this.hideApp = true
      } else {
        this.setPackages(packages)
      }
  },
  mounted() {
      
  },
  data() {
    return {
      appHidden: false,
      authToken: '',
      imgOptions: [
        {
          size: 1,
          outputType: 'jpeg',
          centerBox: true
        }
      ],
      items: [],
    }
  },
  methods: {
    renderImage(item) {
      //return "http://2020.laupheimer-hof.de/wp-content/uploads/elementor/thumbs/DS2_2931-ou76lszaukiojggjwm7lreet6cvgpd55ds7yb0iwgy.jpg"
      return 'https://v1.dirs21.de/TourData/' + item.Image.URI
    },
    setPackages(values) {
      console.log(values)
      this.items = values
    },
    setAuthToken(value) {
      this.authToken = value
    },
    hideApp(value) {
      this.appHidden = value
    },
    handleAuth() {

      const TOKEN = this.authToken
      const BASEURL = 'https://api-default.tocc.dirs21.de/v1/search/View.Package'

      axios.create({
              baseURL: BASEURL,
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+TOKEN
              }
          })
          .get()
          .then(res => {
                  console.log("res");
                  if(typeof res.data != "undefined" && typeof res.data.Value != "undefined") {
                    this.items = res.data.Value
                  }
          });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .swiper-slide-image {
    height: 340px;
    width: 500px;
  }
  .swiper-slide-inner {
    position: relative;
    .slider-item-title-wrapper {
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: rgba(147, 121, 107, .8);
      width: 100%;
      text-align: left;
      padding-bottom: 2rem;
      .slider-item-title {
        color: #fff;
        padding: 2rem 2rem 0 2rem;
      }
      .slider-item-link{
        margin: 0rem 2rem 2rem 2rem;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        border-bottom: 2px solid #fff;
        font-weight: bold;
      }
    }
  }


</style>
